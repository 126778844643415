import io from 'socket.io-client';
import {
  Container,
  Typography,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Box,
  Stack,
  TextField,
  Button,
  Grid,
  Tooltip,
  Zoom,
  Fade,
  Autocomplete,
  FormControl,
  MenuItem,
  CardContent,
  Select,
  CircularProgress,
  Divider,
  List,
  ListItemText,
  ListItemButton,
  Collapse,
  ListItem,
  Avatar,
  ListItemAvatar,
  IconButton,
  useMediaQuery,
  Badge,
  Fab,
  Popover,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';
import { toast } from 'react-toastify';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import ForumIcon from '@mui/icons-material/Forum';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ModeCommentRoundedIcon from '@mui/icons-material/ModeCommentRounded';
import { Form, useFormik, FormikProvider } from 'formik';
import FlagCircleRoundedIcon from '@mui/icons-material/FlagCircleRounded';
import DescriptionIcon from '@mui/icons-material/Description';
import { CalendarMonthOutlined, Close } from '@mui/icons-material';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import CategoryIcon from '@mui/icons-material/Category';
import AttachmentIcon from '@mui/icons-material/Attachment';
import axios from 'axios';
import Moment from 'moment';
import { Link, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import FileUploadModal from '../components/FileUploadModal';
import fileImage from '../assets/file.jpg';
import CardForAttachment from '../components/CardForAttachment';
import Page from '../components/Page';
import Chat from '../components/chat/Chat';

const socket = io(`${process.env.REACT_APP_API_URL}`);

export const ViewTask = () => {
  const [task, setTask] = useState();
  const [attachments, setAttachments] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [manager, setManager] = useState(false);
  const [description, setDescription] = useState('');
  const [open, setOpen] = useState(true);
  const [open2, setOpen2] = useState({ flag: false, id: null });
  const [open1, setOpen1] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [projectMembers, setProjectMembers] = useState([]);
  const taskId = searchParams.get('taskId');
  const projectId = searchParams.get('projectId');
  const matches = useMediaQuery('(max-width:500px)');
  const fullWidth = useMediaQuery('(max-width:1580px)');
  const medium = useMediaQuery('(max-width:1509px)');
  const small = useMediaQuery('(max-width:819px)');
  const [openCollapse, setOpenCollapse] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open7, setOpen7] = useState(false);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [selectedPopup, setSelectedPopup] = useState('');

  const handleOpenPopoverManager = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen6(true);
    event.stopPropagation();
  };

  const handleClosePopoverManager = (event) => {
    setAnchorEl(null);
    setOpen6(false);
  };
  const handleOpenPopoverMember = (event, id) => {
    setAnchorEl1(event.currentTarget);
    setOpen7(true);
    setSelectedPopup(id);
    event.stopPropagation();
  };

  const handleClosePopoverMember = (event) => {
    setAnchorEl1(null);
    setOpen7(false);
  };

  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')).id);

  const fabStyle = {
    right: '5vw',
    position: 'fixed',
    top: '80vh',
    zIndex: '999',
    cursor: 'pointer',
  };

  const checkManager = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/project/manager`, {
        params: { projectId },
      });
      setManager(res.data.data.manager);
    } catch (error) {
      console.log(error);
    }
  };

  const viewTask = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/task/user`, {
        params: { id: taskId },
      })
      .then((res) => {
        console.log('task', res.data.data.task);
        setTask(res.data.data.task);
        setAttachments(res.data.data.task.attachment);
        setDescription(res.data.data.task.description);
        const { members } = res.data.data.task;
        const member = members.find((e) => e.userId._id === user);
        setUnreadMessages(member.unreadCount);
        setIsLoading(false);
      });
  };
  const getProjectMembers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/task/manager/nonmembers`, {
        params: { projectId, taskId },
      });
      setProjectMembers(res.data.data.members.filter((item) => item.isBlocked === false));
    } catch (error) {
      console.log(error);
    }
  };
  const formik = useFormik({
    initialValues: {
      userIds: [],
      taskId,
      projectId,
    },

    onSubmit: async () => {
      console.log('values', values);
      try {
        await axios
          .put(`${process.env.REACT_APP_API_URL}/task/manager/addmembers`, values)
          .then((res) => {
            toast.success(res.data.message);
            handleClose();
            callApis();
          })
          .catch((err) => {
            toast.error('something went wrong');
            console.log(err);
          });
      } catch (error) {
        console.log(error);
        return false;
      }
    },
  });
  const removeMember = async (userId) => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/task/manager/removemember`, {
        userId: open2.id,
        taskId,
        projectId,
      });
      toast.success(res.data.message);
      setOpen2({ flag: false, id: null });
      callApis();
    } catch (error) {
      console.log(error);
    }
  };
  const callApis = async () => {
    viewTask();
    if (manager) {
      await getProjectMembers();
    }
  };

  const handleDialog = () => {
    setOpen1(true);
  };
  const handleDeletePopup = (id) => {
    setOpen2({ flag: true, id });
  };
  const handleClose = () => {
    setOpen1(false);
    setOpen2({ flag: false, id: null });
  };
  const handleStatusChange = async (event) => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/task/manager/status`, {
        status: event.target.value,
        taskId,
        projectId,
      });
      viewTask();
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const handleUpdateStartDate = async (e) => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/task/manager/edit`, {
        taskId,
        projectId,
        startDate: e.target.value,
      });
      toast.success('Start date updated');
      viewTask();
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong');
    }
  };
  const handleUpdateEndDate = async (e) => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/task/manager/edit`, {
        taskId,
        projectId,
        endDate: e.target.value,
      });
      toast.success('End date updated');
      viewTask();
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong');
    }
  };

  const updateDescription = async () => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/task/manager/edit`, {
        taskId,
        description,
        projectId,
      });
      toast.success('Task Description Successfully Updated');
      viewTask();
    } catch (error) {
      console.log(error);
      toast.error(error.resposnse.data.message);
    }
  };

  const handleChatOpen = () => {
    setOpen3(true);
    setTimeout(() => {
      setUnreadMessages(0);
    }, 500);
  };

  const handleChatClose = () => {
    setOpen3(false);
    viewTask();
  };

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    socket.connect();
    socket.on('TASK_MESSAGE', (data) => {
      if (data.taskId === taskId && !open3) {
        setUnreadMessages((prev) => prev + 1);
      }
    });
    return () => {
      socket.off('TASK_MESSAGE');
      // socket.disconnect();
    };
  }, [unreadMessages]);

  useEffect(() => {
    checkManager();
  }, []);

  useEffect(() => {
    callApis();
  }, [manager]);
  const { errors, touched, handleSubmit, handleChange, setFieldValue, values, getFieldProps } = formik;
  return (
    <Page>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container>
          <Badge
            overlap="circular"
            onClick={handleChatOpen}
            invisible={unreadMessages < 1}
            style={fabStyle}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            badgeContent={
              !!unreadMessages && (
                <Avatar
                  sx={{
                    ...(unreadMessages < 10 && { width: 24, height: 24 }),
                    ...(unreadMessages >= 10 && unreadMessages < 100 && { width: 28, height: 28 }),
                    ...(unreadMessages >= 100 && unreadMessages < 100 && { width: 37, height: 28 }),
                    border: 'solid white 3px',
                    bgcolor: 'white',
                    color: '#242c42',
                  }}
                >
                  <Typography>{(!!unreadMessages && unreadMessages) || 0}</Typography>
                </Avatar>
              )
            }
          >
            <Avatar
              size="large"
              className="fab"
              sx={{ width: 54, height: 54, bgcolor: '#475985', ':hover': { background: '#394970' } }}
              color="primary"
              aria-label="add"
            >
              <ForumIcon sx={{ fontSize: '35px', color: 'white' }} />
            </Avatar>
          </Badge>
          {open3 && <Chat handleChatClose={handleChatClose} taskId={taskId} open3={open3} />}
          <Dialog
            open={open1}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <Stack direction="row" justifyContent={'space-between'} marginX={-1}>
                {'Add Members'}
                <Tooltip TransitionComponent={Zoom} title="Close">
                  <CloseRoundedIcon onClick={handleClose} sx={{ color: 'black', cursor: 'pointer' }} />
                </Tooltip>
              </Stack>
            </DialogTitle>
            <DialogContent>
              <FormikProvider value={formik}>
                <Form onSubmit={handleSubmit}>
                  <Stack direction="column">
                    <FormControl sx={{ m: 1, width: { sm: '350px', xs: '250px' } }}>
                      <Autocomplete
                        multiple
                        id="multiple-limit-tags"
                        name="userIds"
                        options={projectMembers}
                        getOptionLabel={(option) => option.fullname}
                        onChange={(e, value) => {
                          const temp = value.map((item, index) => {
                            return item._id;
                          });
                          setFieldValue('userIds', temp);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select members"
                            placeholder="Members"
                            fullWidth
                            onChange={handleChange}
                            error={Boolean(touched.userIds && errors.userIds)}
                          />
                        )}
                      />
                    </FormControl>
                    <Stack mt={2} padding={1} spacing={2} direction="row" justifyContent="center">
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{ width: '100px', bgcolor: '#475985', ':hover': { background: '#394970' } }}
                      >
                        Add
                      </Button>
                    </Stack>
                  </Stack>
                </Form>
              </FormikProvider>
            </DialogContent>
          </Dialog>
          <Dialog
            open={open2.flag}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'Remove member'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to remove this member from Project?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button sx={{ color: '#475985', ':hover': { bgcolor: '#DEE2ED' } }} onClick={handleClose}>
                No
              </Button>
              <Button sx={{ color: '#475985', ':hover': { bgcolor: '#DEE2ED' } }} onClick={removeMember} autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <Grid>
            <Card
              style={{
                padding: '20px',
                borderRadius: '10px',
                // border: '5px solid',
                backgroundColor: '#dee2ed',
                marginBottom: '5px',
              }}
            >
              {' '}
              <Link to={`/dashboard/project/${projectId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography variant="h4" textAlign={'center'}>
                  {task.projectId.name}
                </Typography>
              </Link>
            </Card>
            <Card style={{ padding: '5px' }}>
              <Stack
                direction={{ lg: 'row', md: 'row', sm: 'column-reverse', xs: 'column-reverse' }}
                spacing={2}
                style={{ display: 'flex', justifyContent: 'space-between', margin: '10px' }}
              >
                <Typography variant="h4">{task?.name.toUpperCase()}</Typography>
                <Stack spacing={1} direction="row" justifyContent={'space-between'}>
                  <Stack direction="row" spacing={1}>
                    <Button
                      variant="contained"
                      sx={{ height: '37px', bgcolor: '#475985', ':hover': { background: '#394970' } }}
                      onClick={() => setOpenCollapse((openCollapse) => !openCollapse)}
                      endIcon={openCollapse ? <ExpandLess /> : <ExpandMore />}
                    >
                      Details
                    </Button>
                    <FileUploadModal projectId={projectId} taskId={taskId} />
                  </Stack>
                  <Tooltip TransitionComponent={Zoom} title="Close">
                    <IconButton onClick={() => navigate(-1)}>
                      <CloseRoundedIcon sx={{ color: 'black' }} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
              <Collapse in={openCollapse}>
                <Grid
                  container
                  sx={12}
                  direction="row"
                  // </Box>justify='flex-start'
                  // alignItem='flex-start'
                >
                  <Grid item lg={8} md={7} sm={12} spacing={1} xs={12}>
                    <Card
                      sx={{
                        marginRight: '10px',
                        marginBottom: '10px',
                        marginLeft: '3px',
                        paddingTop: '7px',
                        bgcolor: '#f0f2f7',
                        height: '420px',
                        '@media screen and (max-width: 900px)': {
                          marginRight: '0px',
                          marginLeft: '0px',
                        },
                      }}
                    >
                      <Box
                        sx={{
                          padding: '10px',
                          marginBottom: '10px',
                          bgcolor: '#f0f2f7',
                          '@media screen and (max-width: 900px)': {
                            padding: '5px',
                          },
                        }}
                      >
                        <Grid container sx={12} spacing={1} direction="row" justifyContent="space-between">
                          <Grid md={6} sm={6} xs={6} item marginBottom="10px">
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                <CalendarMonthOutlined sx={{ color: 'green' }} />
                              </Typography>
                              {!(task?.startDate || !manager) ? (
                                <TextField
                                  sx={{ width: '145px' }}
                                  type="date"
                                  size="small"
                                  onBlur={handleUpdateStartDate}
                                  InputLabelProps={{ shrink: true }}
                                  value={task?.startDate ? Moment(task?.startDate).format('YYYY-MM-DD') : null}
                                />
                              ) : (
                                <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                  {task?.startDate ? Moment(task?.startDate).format('DD MMM YY') : "Start Date"}
                                </Typography>
                              )}
                            </Stack>
                          </Grid>

                          <Grid md={6} sm={4} xs={6} item marginBottom="10px">
                            <Stack direction="column" alignItems="flex-end">
                              <Stack direction="row" alignItems="center" spacing={1}>
                                <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                  <CalendarMonthOutlined sx={{ color: 'red' }} />
                                </Typography>
                                {!(task?.endDate || !manager) ? (
                                <TextField
                                  sx={{ width: '145px' }}
                                  type="date"
                                  size="small"
                                  onBlur={handleUpdateEndDate}
                                  InputLabelProps={{ shrink: true }}
                                  value={task?.endDate ? Moment(task?.endDate).format('YYYY-MM-DD') : null}
                                />
                              ) : (
                                <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                  {task?.endDate ? Moment(task?.endDate).format('DD MMM YY') : "End Date"}
                                </Typography>
                              )}
                              </Stack>
                            </Stack>
                          </Grid>

                          <Grid md={12} sm={12} xs={12} item marginBottom="10px">
                            <Stack direction="row" spacing={2}>
                              <Stack direction={'row'}>
                                <CategoryIcon />
                                <Typography style={{ fontSize: '16px', fontWeight: 'bold', paddingLeft: '3px' }}>
                                  Category
                                </Typography>
                              </Stack>
                              <Typography style={{ wordWrap: 'break-word' }} variant="h7">
                                {task?.category}
                              </Typography>
                            </Stack>
                          </Grid>

                          <Grid md={12} sm={12} xs={12} item marginBottom="10px">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Stack direction={'row'}>
                                <PendingActionsIcon />
                                <Typography style={{ fontSize: '16px', fontWeight: 'bold', paddingLeft: '3px' }}>
                                  Status
                                </Typography>
                              </Stack>
                              <FormControl sx={{ minWidth: 120 }}>
                                <Select
                                  size="small"
                                  value={task.status}
                                  onChange={(e) => handleStatusChange(e)}
                                  style={{ pointerEvents: `${manager ? 'all' : 'none'}` }}
                                >
                                  <MenuItem value="PENDING">Pending</MenuItem>
                                  <MenuItem value="IN_PROGRESS">In progress</MenuItem>
                                  <MenuItem value="COMPLETED">Completed</MenuItem>
                                  <MenuItem value="PAUSED">Paused</MenuItem>
                                  <MenuItem value="CANCELLED">Cancelled</MenuItem>
                                </Select>
                              </FormControl>
                            </Stack>
                          </Grid>

                          <Grid md={12} sm={12} xs={12} item marginBottom="10px">
                            <Stack direction="column" paddingTop={3} spacing={0.5}>
                              <Stack direction="row">
                                <DescriptionIcon />
                                <Typography>Description</Typography>
                              </Stack>
                              <Stack direction={{ sm: 'row', xs: 'column' }} alignItems="center" spacing={1}>
                                <TextField
                                  multiline
                                  rows={5}
                                  maxRows={10}
                                  style={{ pointerEvents: `${manager ? 'all' : 'none'}` }}
                                  size="small"
                                  fullWidth
                                  type="text"
                                  value={description}
                                  onChange={(e) => setDescription(e.target.value)}
                                />
                                {manager && (
                                  <LoadingButton
                                    sx={{
                                      height: '40px',
                                      width: { xs: '100%', sm: '80px' },
                                      bgcolor: '#475985',
                                      ':hover': { background: '#394970' },
                                    }}
                                    onClick={updateDescription}
                                    variant="contained"
                                  >
                                    Update
                                  </LoadingButton>
                                )}
                              </Stack>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>
                  </Grid>{' '}
                  <Grid container lg={4} md={5} sm={12} xs={12}>
                    <Box width="100%">
                      <Grid item md={12} sm={12} xs={12}>
                        <Card
                          style={{
                            padding: '10px',
                            marginLeft: { lg: '5px', xl: '5px', md: '2px', sm: '0px', xs: '0px' },
                            marginBottom: '10px',
                            backgroundColor: '#f0f2f7',
                          }}
                        >
                          <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
                            Manager
                          </Typography>
                          <Divider style={{ padding: '5px' }} />
                          {task.members.map((member, i) => (
                            <>
                              {member.role === 'MANAGER' && (
                                <ListItem>
                                  <ListItemAvatar
                                    sx={{
                                      cursor: 'pointer',
                                    }}
                                    onClick={(e) => handleOpenPopoverManager(e)}
                                  >
                                    {!member.userId?.profilePic ? (
                                      <Avatar alt="Remy Sharp" src="https://www.w3schools.com/howto/img_avatar.png" />
                                    ) : (
                                      <Avatar alt="Remy Sharp" src={member.userId?.profilePic} />
                                    )}
                                  </ListItemAvatar>
                                  <Tooltip TransitionComponent={Zoom} title={member?.userId.email}>
                                    <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                      {member.userId.fullname.toUpperCase()}
                                    </Typography>
                                  </Tooltip>
                                </ListItem>
                              )}
                              <Popover
                                open={open6 && member.role === 'MANAGER'}
                                anchorEl={anchorEl}
                                onClose={handleClosePopoverManager}
                                disableRestoreFocus
                                anchorOrigin={{
                                  vertical: 'center',
                                  horizontal: 'center',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'center',
                                }}
                              >
                                <Box
                                  sx={{
                                    p: 2,
                                    maxWidth: 280,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <IconButton
                                    onClick={handleClosePopoverManager}
                                    sx={{ position: 'absolute', top: 0, right: 0 }}
                                  >
                                    <Close />
                                  </IconButton>
                                  <Avatar
                                    src={member?.userId?.profilePic || 'https://www.w3schools.com/howto/img_avatar.png'}
                                    alt="Avatar"
                                    sx={{ width: 200, height: 200 }}
                                  />
                                  <Typography
                                    sx={{
                                      lineHeight: 1.2,
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {member.userId.fullname.toUpperCase()}
                                  </Typography>
                                  <Typography variant="body2" align="center" sx={{ color: 'text.secondary' }}>
                                    {member.userId.email}
                                  </Typography>
                                  <Typography variant="body2" align="center" sx={{ color: 'text.secondary' }}>
                                    {member.userId.accType}
                                  </Typography>
                                </Box>
                              </Popover>
                            </>
                          ))}
                        </Card>
                      </Grid>

                      <Grid item md={12} sm={12} xs={12}>
                        <Box>
                          <Card
                            style={{
                              padding: '10px',
                              marginLeft: { lg: '5px', xl: '5px', md: '2px', sm: '0px', xs: '0px' },
                              marginBottom: '10px',
                              backgroundColor: '#f0f2f7',
                              maxHeight: '300px',
                              overflowY: 'scroll',
                            }}
                          >
                            <ListItemButton onClick={handleClick}>
                              <ListItemText style={{ fontWeight: 'bold' }} primary="Members" />
                              {!open ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={!open}>
                              <List component="div" disablePadding>
                                {manager && (
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{
                                      width: '100%',
                                      border: '1px solid #ccc',
                                      borderRadius: '5px',
                                      cursor: 'pointer',
                                      ':hover': { backgroundColor: '#ccc' },
                                    }}
                                    onClick={handleDialog}
                                  >
                                    <Box
                                      sx={{
                                        height: '30px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '150px',
                                      }}
                                    >
                                      <AddCircleOutlineSharpIcon sx={{ marginRight: '10px' }} />
                                      <Typography sx={{ fontWeight: 'bold' }}>Add Member</Typography>
                                    </Box>
                                  </Stack>
                                )}
                                {task.members.map((member, id) => (
                                  <>
                                    {member.role !== 'MANAGER' && (
                                      <>
                                        {' '}
                                        <ListItem>
                                          <ListItemAvatar
                                            onClick={(e) => handleOpenPopoverMember(e, id)}
                                            sx={{
                                              cursor: 'pointer',
                                            }}
                                          >
                                            <Badge
                                              invisible={!member.userId.isBlocked}
                                              overlap="circular"
                                              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                              badgeContent={
                                                <Icon fontSize="20px" icon="material-symbols:block" color="red" />
                                              }
                                            >
                                              {!member?.userId?.profilePic ? (
                                                <Avatar
                                                  alt="Remy Sharp"
                                                  src="https://www.w3schools.com/howto/img_avatar.png"
                                                />
                                              ) : (
                                                <Avatar alt="Remy Sharp" src={member?.userId?.profilePic} />
                                              )}
                                            </Badge>
                                          </ListItemAvatar>

                                          <Tooltip TransitionComponent={Zoom} title={member.userId.email}>
                                            <ListItemText
                                              primary={
                                                member.userId.fullname.charAt(0).toUpperCase() +
                                                member.userId.fullname.slice(1)
                                              }
                                            />
                                          </Tooltip>
                                          {manager && (
                                            <Stack direction="column" alignItems="flex-end">
                                              <ListItemButton
                                                onClick={() => handleDeletePopup(member.userId._id)}
                                                alignItems="flex-end"
                                              >
                                                <DeleteOutlinedIcon />
                                              </ListItemButton>
                                            </Stack>
                                          )}
                                          <Popover
                                            open={open7 && id === selectedPopup}
                                            anchorEl={anchorEl1}
                                            onClose={handleClosePopoverMember}
                                            disableRestoreFocus
                                            anchorOrigin={{
                                              vertical: 'center',
                                              horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                              vertical: 'top',
                                              horizontal: 'center',
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                p: 2,
                                                maxWidth: 280,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                flexDirection: 'column',
                                              }}
                                            >
                                              <IconButton
                                                onClick={handleClosePopoverMember}
                                                sx={{ position: 'absolute', top: 0, right: 0 }}
                                              >
                                                <Close />
                                              </IconButton>
                                              <Avatar
                                                src={
                                                  member?.userId?.profilePic ||
                                                  'https://www.w3schools.com/howto/img_avatar.png'
                                                }
                                                alt="Avatar"
                                                sx={{ width: 200, height: 200 }}
                                              />
                                              <Typography
                                                sx={{
                                                  lineHeight: 1.2,
                                                  fontWeight: 'bold',
                                                }}
                                              >
                                                {member?.userId?.fullname.toUpperCase()}
                                              </Typography>
                                              <Typography
                                                variant="body2"
                                                align="center"
                                                sx={{ color: 'text.secondary' }}
                                              >
                                                {member?.userId?.email}
                                              </Typography>
                                              <Typography
                                                variant="body2"
                                                align="center"
                                                sx={{ color: 'text.secondary' }}
                                              >
                                                {member?.userId?.accType}
                                              </Typography>
                                            </Box>
                                          </Popover>
                                        </ListItem>
                                      </>
                                    )}
                                  </>
                                ))}
                              </List>
                            </Collapse>
                          </Card>
                        </Box>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Collapse>
              {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography variant="h4">{task?.name.toUpperCase()}</Typography>
                </div> */}
              {/* file attachments */}
              <Stack direction={'row'} style={{ marginLeft: '30px' }} alignItems="center">
                <Typography variant="h6">File Attachments</Typography>
                <AttachmentIcon style={{ margin: '8px' }} />
              </Stack>
              <Stack direction={'row'} justifyContent="flex-start">
                <Stack
                  direction={'row'}
                  padding={matches ? 1 : 1}
                  sx={{
                    flexWrap: 'wrap',
                    ...(medium && { width: '780px' }),
                    ...(small && { width: '390px' }),
                  }}
                  columnGap={2}
                  alignItems="center"
                  rowGap={2}
                  justifyContent={'flex-start'}
                  alignContent="flex-start"
                >
                  {attachments.length >= 1 &&
                    attachments?.map((item, index) => {
                      return <CardForAttachment file={item} />;
                    })}
                </Stack>
              </Stack>
              {attachments.length < 1 && (
                <Stack
                  direction={'row'}
                  padding={matches ? 1 : 1}
                  sx={{ flexWrap: 'wrap', margin: 'auto' }}
                  columnGap={2}
                  alignItems="center"
                  rowGap={2}
                  justifyContent={'flex-start'}
                  alignContent="flex-start"
                >
                  <Box style={{ height: '200px', width: '100%' }}>
                    <Typography textAlign={'center'} style={{ padding: '70px 0px' }}>
                      No Attachments Found.
                    </Typography>
                  </Box>
                </Stack>
              )}
            </Card>
          </Grid>
        </Container>
      )}
    </Page>
  );
};
