import { Link } from 'react-router-dom';
// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography, Grid } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
// components
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

export default function AppWidgetSummary({ data }) {
  const color = [
    "#f0f2f7"
  ];
  return data.map((item, key) => {
    return (
      <Grid item key={key} xs={12} sm={6} md={4} xl={4}>
        <Link style={{ textDecoration: 'none' }} to={`/dashboard/projects/?category=${item.category}`}>
          <Card
            key={key}
            sx={{
              paddingBottom: 1,
              paddingTop: 3,
              boxShadow: 0,
              textAlign: 'center',
              color: "#02071f",
              bgcolor: color[key % 1],
            }}
          >
            <Typography marginBottom={2} variant="h5">
              {item.category.split('_').join(' ')}
            </Typography>
            <IconWrapperStyle
              sx={{
                color: "#0f5673",
                backgroundColor: "#ccdcfc"
              }}
            >
              <Typography variant="h6">{item.totalProjects}</Typography>
            </IconWrapperStyle>
          </Card>
        </Link>
      </Grid>
    );
  });
}
