import { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Moment from 'moment';
import axios from 'axios';

// material
import {
  Container,
  Stack,
  Typography,
  CircularProgress,
  Card,
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CardContent,
  AvatarGroup,
  Avatar,
  Divider,
  FormControl,
  Autocomplete,
  TextField,
  Button,
  Tabs,
  Tab,
  TablePagination,
  Tooltip,
  Zoom,
  List,
  ListItemText,
  ListItemButton,
  Collapse,
  ListItem,
  ListItemAvatar,
  Badge,
  useMediaQuery,
  IconButton,
  Popover,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DescriptionIcon from '@mui/icons-material/Description';
import { Icon } from '@iconify/react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Form, useFormik, FormikProvider } from 'formik';
import { toast } from 'react-toastify';

import ExpandMore from '@mui/icons-material/ExpandMore';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PeopleSharpIcon from '@mui/icons-material/PeopleSharp';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FlagCircleRoundedIcon from '@mui/icons-material/FlagCircleRounded';
import CategoryIcon from '@mui/icons-material/Category';
import MessageIcon from '@mui/icons-material/Message';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { CalendarMonthOutlined, Close, ManageSearchRounded } from '@mui/icons-material';
import ListTasks from '../components/ListTasks';

// components
import Page from '../components/Page';
import { TaskDatePopup } from './TaskDatePopup';

// ----------------------------------------------------------------------

export default function Project() {
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname.split('/')[3];
  const [project, setProject] = useState([]);
  const [nonmembers, setNonmembers] = useState([]);
  const [isloading, setIsLoading] = useState(true);
  const [pages, setPages] = useState(0);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')).fullname);
  const [manager, setManager] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [projectCheck, setProjectCheck] = useState(0);
  const [open, setOpen] = useState(true);
  const [open1, setOpen1] = useState(false);
  const [description, setDescription] = useState('');
  const [open2, setOpen2] = useState({ flag: false, id: null });
  const [openCollapse, setOpenCollapse] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open7, setOpen7] = useState(false);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [selectedPopup, setSelectedPopup] = useState('');

  const handleOpenPopoverManager = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen6(true);
    event.stopPropagation();
  };

  const handleClosePopoverManager = (event) => {
    setAnchorEl(null);
    setOpen6(false);
  };
  const handleOpenPopoverMember = (event, id) => {
    setAnchorEl1(event.currentTarget);
    setOpen7(true);
    setSelectedPopup(id);
    event.stopPropagation();
  };

  const handleClosePopoverMember = (event) => {
    setAnchorEl1(null);
    setOpen7(false);
  };

  const matches = useMediaQuery('(max-width:900px)');

  const formik = useFormik({
    initialValues: {
      userIds: [],
      projectId: path,
    },

    onSubmit: async () => {
      console.log('values', values);
      try {
        await axios
          .put(`${process.env.REACT_APP_API_URL}/project/manager/add-members`, values)
          .then((res) => {
            toast.success(res.data.message);
            handleClose();
            callApis();
          })
          .catch((err) => {
            toast.error(err.response.data.message);
            console.log(err);
          });
      } catch (error) {
        console.log(error);
        return false;
      }
    },
  });

  const handleClick = () => {
    setOpen(!open);
  };

  const getProject = async () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/project/user`, {
        params: { id: path },
      })
      .then((res) => {
        if (!res.data.isError) {
          setProject(res.data.data.project);
          setDescription(res.data.data.project.description);
          if (res.data.data.project.manager.fullname === user) {
            setManager(true);
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getNonmembers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/project/manager/nonmembers`, {
        params: { projectId: path },
      });
      console.log('nonMembersss', res);
      setNonmembers(res.data.data.data.nonmembers);
    } catch (error) {
      console.log(error);
    }
  };

  const removeMember = async (userId) => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/project/manager/remove-member`, {
        userId: open2.id,
        projectId: path,
      });
      toast.success(res.data.message);
      setOpen2({ flag: false, id: null });
      callApis();
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateStartDate = async (e) => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/project/manager/edit`, {
        projectId: path,
        startDate: e.target.value,
      });
      toast.success('Start date updated');
      getProject();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };
  const handleUpdateEndDate = async (e) => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/project/manager/edit`, {
        projectId: path,
        endDate: e.target.value,
      });
      toast.success('End date updated');
      getProject();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const handleClose = (e, flag = '') => {
    setOpen1(false);
    setOpen2(false);
    // setOpen3(false);
    if (flag) {
      callApis();
    }
  };
  const handleDialog = () => {
    setOpen1(true);
  };
  const handleDeletePopup = (id) => {
    setOpen2({ flag: true, id });
  };

  const updateDescription = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/project/manager/edit`, {
        projectId: path,
        description,
      });
      toast.success('Project Description Successfully Updated');
      getProject();
    } catch (error) {
      console.log(error);
      toast.error(error.resposnse.data.message);
    }
  };

  const callApis = async () => {
    setIsLoading(true);
    await getProject();
    if (manager) {
      await getNonmembers();
    }
  };

  useEffect(() => {
    callApis();
  }, []);
  useEffect(() => {
    if (manager) {
      getNonmembers();
    }
  }, [manager]);
  const { errors, touched, handleSubmit, handleChange, setFieldValue, values, getFieldProps } = formik;

  return (
    <Page title="Dashboard: Project">
      {isloading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container>
          <Dialog
            open={open1}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <Stack direction="row" justifyContent={'space-between'} marginX={-1}>
                {'Add Members'}
                <Tooltip TransitionComponent={Zoom} title="Close">
                  <CloseRoundedIcon onClick={handleClose} sx={{ color: 'black', cursor: 'pointer' }} />
                </Tooltip>
              </Stack>
            </DialogTitle>
            <DialogContent>
              <FormikProvider value={formik}>
                <Form onSubmit={handleSubmit}>
                  <Stack direction="column">
                    <FormControl sx={{ m: 1, width: { sm: '350px', xs: '250px' } }}>
                      <Autocomplete
                        multiple
                        id="multiple-limit-tags"
                        name="userIds"
                        options={nonmembers}
                        getOptionLabel={(option) => option.fullname}
                        onChange={(e, value) => {
                          const temp = value.map((item, index) => {
                            return item._id;
                          });
                          setFieldValue('userIds', temp);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select members"
                            placeholder="Members"
                            fullWidth
                            onChange={handleChange}
                            error={Boolean(touched.userIds && errors.userIds)}
                          />
                        )}
                      />
                    </FormControl>
                    <Stack mt={2} padding={1} spacing={2} direction="row" justifyContent="center">
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{ bgcolor: '#575151', ':hover': { background: '#423e3e' }, width: '100px' }}
                      >
                        Add
                      </Button>
                    </Stack>
                  </Stack>
                </Form>
              </FormikProvider>
            </DialogContent>
          </Dialog>
          <Dialog
            open={open2.flag}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'Remove member'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to remove this member from Project?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button sx={{ color: '#575151', ':hover': { bgcolor: '#E9E2E2' } }} onClick={handleClose}>
                No
              </Button>
              <Button sx={{ color: '#575151', ':hover': { bgcolor: '#E9E2E2' } }} onClick={removeMember} autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <Card
            sx={{
              padding: '20px',
              marginBottom: '5px',
              bgcolor: '#ded3d3',
            }}
          >
            <Stack direction="row" justifyContent="center">
              <Typography variant="h4">{project?.name.toUpperCase()}</Typography>
            </Stack>
          </Card>

          <Card
            sx={{
              padding: '10px 10px 15px 10px',
              '@media screen and (max-width: 450px)': {
                padding: '0px',
              },
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              justifyContent={{ xs: 'space-between', md: 'flex-end' }}
              padding="10px"
              alignItems={'center'}
            >
              <Stack direction={'row'} spacing={1}>
                <IconButton variant="contained" sx={{ color: '#575151' }} onClick={() => setOpen4((open4) => !open4)}>
                  <CalendarMonthOutlined />
                </IconButton>
                <Button
                  variant="contained"
                  sx={{ bgcolor: '#575151', ':hover': { background: '#423e3e' }, height: '36px' }}
                  onClick={() => setOpenCollapse((openCollapse) => !openCollapse)}
                  endIcon={openCollapse ? <ExpandLess /> : <ExpandMore />}
                >
                  Details
                </Button>
                {manager && (
                  <Link to={`/dashboard/project/create/${path}`} style={{ textDecoration: 'none' }}>
                    <Button
                      variant="contained"
                      sx={{ bgcolor: '#575151', ':hover': { background: '#423e3e' } }}
                      startIcon={<AddCircleOutlineSharpIcon />}
                    >
                      Task
                    </Button>
                  </Link>
                )}
              </Stack>
              <Tooltip TransitionComponent={Zoom} title="Close">
                <CloseRoundedIcon onClick={() => navigate(-1)} sx={{ color: 'black' }} />
              </Tooltip>
            </Stack>
            <Collapse in={openCollapse}>
              <Grid
                style={{ padding: '5px' }}
                container
                sx={12}
                direction="row"
                // </Box>justify='flex-start'
                // alignItem='flex-start'
              >
                <Grid lg={8} md={7} sm={12} spacing={1} xs={12}>
                  <Card
                    sx={{
                      bgcolor: '#ede8e8',
                      minHeight: '422px',
                      marginRight: { lg: '10px', xl: '10px', md: '5px', sm: '0px', xs: '0px' },
                      marginBottom: '10px',
                      paddingTop: '5px',
                    }}
                  >
                    <Box
                      sx={{
                        padding: ' 10px',
                        '@media screen and (max-width: 450px)': {
                          padding: '5px',
                        },
                      }}
                    >
                      <Grid container sx={12} spacing={1} direction="row" justifyContent="space-between">
                        <Grid md={6} sm={6} xs={6} item marginBottom="10px">
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                              <CalendarMonthOutlined sx={{ color: 'green' }} />
                            </Typography>
                            {!(project?.startDate || !manager) ? (
                              <TextField
                                type="date"
                                size="small"
                                sx={{ width: '145px' }}
                                onBlur={handleUpdateStartDate}
                                InputLabelProps={{ shrink: true }}
                                value={Moment(project?.startDate).format('YYYY-MM-DD')}
                              />
                            ) : (
                              <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                               {project?.startDate ? Moment(project?.startDate).format('DD MMM YY') : 'End Date'}
                              </Typography>
                            )}
                          </Stack>
                        </Grid>

                        <Grid md={6} sm={4} xs={6} item marginBottom="10px">
                          <Stack
                            direction="column"
                            alignItems={{ lg: 'flex-end', md: 'flex-end', sm: 'flex-start', xs: 'flex-start' }}
                          >
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                <CalendarMonthOutlined sx={{ color: 'red' }} />
                              </Typography>
                              {!(project?.endDate || !manager) ? (
                                <TextField
                                  sx={{ width: '145px' }}
                                  type="date"
                                  size="small"
                                  onChange={handleUpdateEndDate}
                                  InputLabelProps={{ shrink: true }}
                                  value={Moment(project?.endDate).format('YYYY-MM-DD')}
                                />
                              ) : (
                                <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                  {project?.endDate ? Moment(project?.endDate).format('DD MMM YY') : 'End Date'}
                                </Typography>
                              )}
                            </Stack>
                          </Stack>
                        </Grid>

                        <Grid md={12} sm={12} xs={12} item marginBottom="10px">
                          <Stack>
                            <Stack direction={'row'}>
                              <CategoryIcon />
                              <Typography style={{ fontSize: '16px', fontWeight: 'bold', paddingLeft: '3px' }}>
                                Category
                              </Typography>
                            </Stack>
                            <Typography style={{ wordWrap: 'break-word' }} variant="h7">
                              {project.category}
                            </Typography>
                          </Stack>
                        </Grid>

                        <Grid md={12} sm={12} xs={12} item marginBottom="10px">
                          <Stack>
                            <Stack direction={'row'}>
                              <LocationOnIcon />
                              <Typography style={{ fontSize: '16px', fontWeight: 'bold', paddingLeft: '3px' }}>
                                Location
                              </Typography>
                            </Stack>
                            <Typography variant="h7">{project.location}</Typography>
                          </Stack>
                        </Grid>

                        <Grid md={12} sm={12} xs={12} item marginBottom="5px">
                          <Stack direction="column" spacing={0.5}>
                            <Stack direction="row">
                              <DescriptionIcon />
                              <Typography
                                style={{
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                  paddingLeft: '3px',
                                  paddingBottom: '5px',
                                }}
                              >
                                Description
                              </Typography>
                            </Stack>
                            <Stack
                              direction={{ md: 'row', sm: 'column', xs: 'column' }}
                              alignItems="center"
                              spacing={1}
                            >
                              <TextField
                                multiline
                                rows={5}
                                maxRows={10}
                                style={{ pointerEvents: `${manager ? 'all' : 'none'}` }}
                                size="small"
                                fullWidth
                                type="text"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                              />
                              {manager && (
                                <LoadingButton
                                  sx={{
                                    height: '40px',
                                    width: { xs: '100%', sm: '80px' },
                                    bgcolor: '#575151',
                                    ':hover': { background: '#423e3e' },
                                  }}
                                  onClick={updateDescription}
                                  variant="contained"
                                >
                                  Update
                                </LoadingButton>
                              )}
                            </Stack>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card>
                </Grid>{' '}
                <Grid container lg={4} md={5} sm={12} xs={12}>
                  <Box width="100%">
                    <Grid item md={12} sm={12} xs={12}>
                      <Card
                        style={{
                          padding: '10px',
                          marginLeft: { lg: '5px', xl: '5px', md: '2px', sm: '0px', xs: '0px' },
                          marginBottom: '10px',
                          backgroundColor: '#ede8e8',
                        }}
                      >
                        <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
                          Manager
                        </Typography>
                        <Divider style={{ padding: '5px' }} />
                        <ListItem>
                          <ListItemAvatar
                            sx={{
                              cursor: 'pointer',
                            }}
                            onClick={(e) => handleOpenPopoverManager(e)}
                          >
                            {!project?.manager?.profilePic ? (
                              <Avatar alt="Remy Sharp" src="https://www.w3schools.com/howto/img_avatar.png" />
                            ) : (
                              <Avatar alt="Remy Sharp" src={project?.manager?.profilePic} />
                            )}
                          </ListItemAvatar>
                          <Tooltip TransitionComponent={Zoom} title={project?.manager.email}>
                            <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                              {project?.manager.fullname.toUpperCase()}
                            </Typography>
                          </Tooltip>
                        </ListItem>
                        <Popover
                          open={open6}
                          anchorEl={anchorEl}
                          onClose={handleClosePopoverManager}
                          disableRestoreFocus
                          anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                        >
                          <Box
                            sx={{
                              p: 2,
                              maxWidth: 280,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              flexDirection: 'column',
                            }}
                          >
                            <IconButton
                              onClick={handleClosePopoverManager}
                              sx={{ position: 'absolute', top: 0, right: 0 }}
                            >
                              <Close />
                            </IconButton>
                            <Avatar
                              src={project?.manager?.profilePic || 'https://www.w3schools.com/howto/img_avatar.png'}
                              alt="Avatar"
                              sx={{ width: 200, height: 200 }}
                            />
                            <Typography
                              sx={{
                                lineHeight: 1.2,
                                fontWeight: 'bold',
                              }}
                            >
                              {project?.manager.fullname.toUpperCase()}
                            </Typography>
                            <Typography variant="body2" align="center" sx={{ color: 'text.secondary' }}>
                              {project?.manager.email}
                            </Typography>
                            <Typography variant="body2" align="center" sx={{ color: 'text.secondary' }}>
                              {project?.manager.accType}
                            </Typography>
                          </Box>
                        </Popover>
                      </Card>
                    </Grid>

                    <Grid item md={12} sm={12} xs={12}>
                      <Box>
                        <Card
                          style={{
                            padding: '10px',
                            marginLeft: { lg: '5px', xl: '5px', md: '2px', sm: '0px', xs: '0px' },
                            marginBottom: '10px',
                            backgroundColor: '#ede8e8',
                            maxHeight: '300px',
                            overflowY: 'scroll',
                          }}
                        >
                          <ListItemButton onClick={handleClick}>
                            <ListItemText style={{ fontWeight: 'bold' }} primary="Members" />
                            {!open ? <ExpandLess /> : <ExpandMore />}
                          </ListItemButton>
                          <Collapse in={!open}>
                            <List component="div" disablePadding>
                              {manager && (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="center"
                                  sx={{
                                    width: '100%',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    ':hover': { backgroundColor: '#ccc' },
                                  }}
                                  onClick={handleDialog}
                                >
                                  <Box
                                    sx={{
                                      height: '30px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      width: '150px',
                                    }}
                                  >
                                    <AddCircleOutlineSharpIcon sx={{ marginRight: '10px' }} />
                                    <Typography sx={{ fontWeight: 'bold' }}>Add Member</Typography>
                                  </Box>
                                </Stack>
                              )}
                              {project?.members.map((mdata, id) => (
                                <ListItem key={id} fullWidth>
                                  <ListItemAvatar
                                    onClick={(e) => handleOpenPopoverMember(e, id)}
                                    sx={{
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <Badge
                                      invisible={!mdata.isBlocked}
                                      overlap="circular"
                                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                      badgeContent={<Icon fontSize="20px" icon="material-symbols:block" color="red" />}
                                    >
                                      {!mdata.profilePic ? (
                                        <Avatar alt="Remy Sharp" src="https://www.w3schools.com/howto/img_avatar.png" />
                                      ) : (
                                        <Avatar alt="Remy Sharp" src={mdata.profilePic} />
                                      )}
                                    </Badge>
                                  </ListItemAvatar>
                                  <Tooltip TransitionComponent={Zoom} title={mdata.email}>
                                    <ListItemText
                                      primary={mdata.fullname.charAt(0).toUpperCase() + mdata.fullname.slice(1)}
                                    />
                                  </Tooltip>
                                  {manager && (
                                    <Stack direction="column" alignItems="flex-end">
                                      <ListItemButton
                                        onClick={() => handleDeletePopup(mdata._id)}
                                        alignItems="flex-end"
                                      >
                                        <DeleteOutlinedIcon />
                                      </ListItemButton>
                                    </Stack>
                                  )}
                                  <Popover
                                    open={open7 && id === selectedPopup}
                                    anchorEl={anchorEl1}
                                    onClose={handleClosePopoverMember}
                                    disableRestoreFocus
                                    anchorOrigin={{
                                      vertical: 'center',
                                      horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'center',
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        p: 2,
                                        maxWidth: 280,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                      }}
                                    >
                                      <IconButton
                                        onClick={handleClosePopoverMember}
                                        sx={{ position: 'absolute', top: 0, right: 0 }}
                                      >
                                        <Close />
                                      </IconButton>
                                      <Avatar
                                        src={mdata.profilePic || 'https://www.w3schools.com/howto/img_avatar.png'}
                                        alt="Avatar"
                                        sx={{ width: 200, height: 200 }}
                                      />
                                      <Typography
                                        sx={{
                                          lineHeight: 1.2,
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        {mdata.fullname.toUpperCase()}
                                      </Typography>
                                      <Typography variant="body2" align="center" sx={{ color: 'text.secondary' }}>
                                        {mdata.email}
                                      </Typography>
                                      <Typography variant="body2" align="center" sx={{ color: 'text.secondary' }}>
                                        {mdata.accType}
                                      </Typography>
                                    </Box>
                                  </Popover>
                                </ListItem>
                              ))}
                            </List>
                          </Collapse>
                        </Card>
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Collapse>
            <Grid mt={0}>
              <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '10px', paddingTop: '10px' }}>
                <Typography variant="h5">TASKS</Typography>
              </div>
            </Grid>
            <Box sx={{ margin: { lg: '0px', xl: '0x', md: '0px', sm: '5px', xs: '5px' } }}>
              <ListTasks />
            </Box>
          </Card>
          {open4 && <TaskDatePopup manager={manager} setOpen4={setOpen4} />}
        </Container>
      )}
    </Page>
  );
}
