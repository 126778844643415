import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from './Page';
import Logo from './Logo';
// sections
import AddUserForm from './AddUserForm';
import AuthSocial from '../sections/auth/AuthSocial';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  // minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  // backgroundColor: 'black',
  // padding: theme.spacing(12, 0),
  padding: '0px',
}));

// ----------------------------------------------------------------------

export default function AddUser({ closePopup,getUsers }) {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Container>
      <ContentStyle>
        <Typography variant="h4" gutterBottom>
          Create a New Memeber
        </Typography>
        {/* <Typography sx={{ color: 'text.secondary', mb: 5 }}>prakarti</Typography>  */}
        {/* <AuthSocial /> */}
        <AddUserForm closePopup={closePopup} getUsers={getUsers} />
      </ContentStyle>
    </Container>
  );
}
