import {
  Card,
  Container,
  Grid,
  Stack,
  Box,
  Button,
  Typography,
  CardContent,
  TablePagination,
  CircularProgress,
  AvatarGroup,
  Tooltip,
  Avatar,
  Zoom,
  Divider,
  IconButton,
  TextField,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import FlagCircleRoundedIcon from '@mui/icons-material/FlagCircleRounded';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { CalendarMonthOutlined } from '@mui/icons-material';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useParams, Link, useSearchParams, useNavigate } from 'react-router-dom';
import Logo, { CardLogo } from '../components/Logo';
import Page from '../components/Page';

export const SubTaskView = () => {
  const [task, setTask] = useState([]);
  const [taskCount, setTaskCount] = useState(0);
  const [manager, setManager] = useState(false);
  const [pages, setPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const size = 9;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  console.log('searchParams', searchParams);
  const category = searchParams.get('category');
  const id = searchParams.get('id');
  const [input, setInput] = useState(1);

  const checkManager = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/project/manager`, {
        params: { projectId: id },
      });
      setManager(res.data.data.manager);
    } catch (error) {
      console.log(error);
    }
  };

  const getTask = (newPage) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/task/user/category/all`, {
        params: { projectId: id, category, page: newPage, size },
      })
      .then((res) => {
        console.log('task', res);
        setTask(res.data.data.data);
        setIsLoading(false);
        setTaskCount(res.data.data.maxRecord);
      });
  };

  const goToPage = () => {
    console.log('hi');
    if (input > Math.ceil(taskCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    getTask(input - 1 >= 0 ? input - 1 : 0);
  };

  const handlePageChange = (event, newPage) => {
    setPages(newPage);
    setIsLoading(true);
    getTask(newPage);
  };
  useEffect(() => {
    checkManager();
  }, []);
  useEffect(() => {
    getTask();
  }, []);

  const TaskColors = ['#F2E9DB', '#F2E9DB'];

  return (
    <Page>
      <Container>
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Card
              style={{
                backgroundColor: '#EBDECB',
                padding: '20px',
                borderRadius: '10px',
                // border: '5px solid',
                height: '100%',
                marginBottom: '5px',
              }}
            >
              <Link to={`/dashboard/project/${id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography variant="h4" textAlign={'center'}>
                  {task[0].projectData.name}
                </Typography>{' '}
              </Link>
            </Card>
            <Card
              sx={{
                backgroundColor: 'white',
                paddingY: '20px',
                paddingX: '10px',
                borderRadius: '10px',
                '@media screen and (max-width: 450px)': {
                  paddingX: '0px',
                },
              }}
            >
              {manager && (
                <Stack
                  direction={{ lg: 'row', md: 'row', sm: 'column-reverse', xs: 'column-reverse' }}
                  spacing={1}
                  justifyContent="space-between"
                  paddingX="10px"
                >
                  <Typography variant="h5">{category}</Typography>
                  <Stack direction="row" spacing={1} justifyContent="space-between">
                    {manager && (
                      <Button
                        variant="contained"
                        startIcon={<AddCircleOutlineSharpIcon />}
                        sx={{ height: '40px', bgcolor: '#6b4f25', ':hover': { background: '#543d1b' } }}
                        onClick={() => navigate(`/dashboard/project/task/create?category=${category}&id=${id}`)}
                      >
                        Task
                      </Button>
                    )}
                    <Tooltip TransitionComponent={Zoom} title="Close">
                      <IconButton onClick={() => navigate(-1)}>
                        <CloseRoundedIcon sx={{ color: 'black' }} />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Stack>
              )}

              {!manager && (
                <Stack direction="row" alignItems="center" justifyContent="space-between" paddingX="10px">
                  <Typography variant="h5">{category}</Typography>
                  <Tooltip TransitionComponent={Zoom} title="Close">
                    <IconButton onClick={() => navigate(-1)}>
                      <CloseRoundedIcon sx={{ color: 'black' }} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              )}

              <Stack direction="row" spacing={2} paddingX={1}>
                <TextField
                  size="small"
                  sx={{
                    maxWidth: '100px',
                    maxHeight: '40px',
                  }}
                  type="number"
                  component="div"
                  label="Go to"
                  value={input}
                  onInput={(e) => setInput(e.target.value)}
                />
                <Button
                  variant="contained"
                  onClick={goToPage}
                  sx={{ maxHeight: '40px', bgcolor: '#6b4f25', ':hover': { background: '#543d1b' } }}
                >
                  Go
                </Button>
              </Stack>

              <Grid container padding="10px" spacing={1} sx={12}>
                {task?.map((item, index) => (
                  <Grid item key={index} sm={6} xs={12} md={4}>
                    <Link
                      to={`/dashboard/project/task/view?projectId=${id}&taskId=${item._id}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <Card
                        style={{
                          background: TaskColors[index % 1],
                          color: 'black',
                          paddingBottom: '5px',
                          height: '100%',
                        }}
                      >
                        <CardContent>
                          <Stack direction="row" spacing={1} paddingBottom="20px" alignItems={'center'}>
                            <CardLogo />
                            <Typography
                              whiteSpace="nowrap"
                              overflow="hidden"
                              textOverflow="ellipsis"
                              style={{ fontSize: '19px', fontWeight: 'bold', paddingLeft: '3px', paddingTop: '10px' }}
                            >
                              {item.name?.toUpperCase()}
                            </Typography>
                          </Stack>
                          <Grid container rowGap={1}>
                            <Grid md={6} sm={6} xs={6} item>
                              <Stack
                                direction="row"
                                sx={{ width: '100%' }}
                                alignItems={'center'}
                                justifyContent="start"
                              >
                                {item.startDate && (
                                  <>
                                    <Stack direction="row" alignItems={'start'}>
                                      <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                        <CalendarMonthOutlined sx={{ color: 'green' }} />
                                      </Typography>
                                      {/* <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>Start Date</Typography> */}
                                    </Stack>
                                    <Typography style={{ fontSize: '15px' }}>
                                      {Moment(item.startDate).format('DD MMM YYYY')}
                                    </Typography>
                                  </>
                                )}
                              </Stack>
                            </Grid>

                            <Grid md={6} sm={6} xs={6} item>
                              <Stack
                                direction="row"
                                sx={{ width: '100%' }}
                                alignItems={'center'}
                                justifyContent="start"
                              >
                                {item.endDate && (
                                  <>
                                    <Stack direction="row" alignItems={'start'}>
                                      <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                        <CalendarMonthOutlined sx={{ color: 'red' }} />
                                      </Typography>
                                      {/* <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>Start Date</Typography> */}
                                    </Stack>
                                    <Typography style={{ fontSize: '15px' }}>
                                      {Moment(item.endDate).format('DD MMM YYYY')}
                                    </Typography>
                                  </>
                                )}
                              </Stack>
                            </Grid>

                            <Grid md={6} sm={6} xs={6} item>
                              <Stack direction>
                                <AttachFileIcon />
                                <Typography style={{ fontSize: '16px', fontWeight: 'bold', paddingLeft: '5px' }}>
                                  {item.attachmentsCount} Files
                                </Typography>
                              </Stack>
                            </Grid>

                            <Grid md={12} sm={12} xs={12} item>
                              <AvatarGroup max={6} sx={{ display: 'flex', justifyContent: 'left', paddingTop: '10px' }}>
                                {item?.members.map((mdata, id) => (
                                  <Tooltip key={id} TransitionComponent={Zoom} title={mdata.email}>
                                    {!mdata.profilePic ? (
                                      <Avatar alt="" key={id} sx={{ bgcolor: '#d1a360' }}>
                                        {mdata.fullname.charAt(0).toUpperCase()}
                                      </Avatar>
                                    ) : (
                                      <Avatar alt="" key={id} src={mdata.profilePic} />
                                    )}
                                  </Tooltip>
                                ))}
                              </AvatarGroup>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Link>
                  </Grid>
                ))}
              </Grid>
              <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={taskCount}
                page={pages}
                onPageChange={handlePageChange}
                rowsPerPage={size}
                showFirstButton
                showLastButton
              />
            </Card>
          </>
        )}
      </Container>
    </Page>
  );
};
