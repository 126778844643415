import * as React from 'react';
import Menu from '@mui/material/Menu';
import { Formik, Form } from 'formik';
import { IconButton, TextField, Typography, Button, Stack, Box, FormControl, Select, MenuItem } from '@mui/material';
import Iconify from './Iconify';

const Filter = ({ options, applyFilters }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState([null, null]);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div>
        <Box style={{ display: 'flex', justifyContent: 'end' }}>
          <IconButton onClick={handleClick}>
            <Iconify icon="ic:round-filter-list" />
            <Typography variant="h6"> Filter</Typography>
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <Formik
              initialValues={{
                name: options?.name || '',
                category: options?.category || '',
                location: options?.location || '',
                startDate: options?.startDate || '',
                endDate: options?.endDate || '',
                userId: options?.userId || ''
              }}
              onSubmit={async (values) => {
                applyFilters(values);
                setAnchorEl(null);
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <Form style={{ padding: '10px' }}>
                  <Stack direction="column">
                    <Stack direction="row" spacing={1} justifyContent="space-between">
                      <TextField
                        select
                        style={{ paddingBottom: '10px' }}
                        label="Category"
                        value={values.category}
                        name="category"
                        fullWidth
                        placeholder="Select Category"
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                        error={Boolean(touched.category && errors.category)}
                        helperText={touched.category && errors.category}
                      >
                        <MenuItem value="">All Categories</MenuItem>
                        <MenuItem value="COMMERCIAL">Commercial</MenuItem>
                        <MenuItem value="COMMERCIAL_INTERIOR">Commercial Interior</MenuItem>
                        <MenuItem value="CULTURAL">Cultural</MenuItem>
                        <MenuItem value="EDUCATIONAL">Educational</MenuItem>
                        <MenuItem value="HOSPITALITY">Hospitality</MenuItem>
                        <MenuItem value="RESIDENTIAL">Residential</MenuItem>
                      </TextField>
                    </Stack>
                    <TextField
                      style={{ paddingBottom: '10px' }}
                      fullWidth
                      id="name"
                      name="name"
                      label="Project Name"
                      value={values.name}
                      onChange={handleChange}
                    />
                      <TextField
                      style={{ paddingBottom: '10px' }}
                      fullWidth
                      id="userId"
                      name="userId"
                      type="text"
                      label="User ID"
                      value={values.userId}
                      onChange={handleChange} />

                    <TextField
                      style={{ paddingBottom: '10px' }}
                      fullWidth
                      id="location"
                      name="location"
                      type="text"
                      label="Location"
                      value={values.location}
                      onChange={handleChange}
                    />

                    <Stack direction={'row'} spacing={2}>
                      <TextField
                        type="date"
                        name="startDate"
                        label="Start Date"
                        InputLabelProps={{ shrink: true }}
                        value={values.startDate}
                        onChange={handleChange}
                      />
                      <p style={{ paddingTop: '15px' }}>to</p>
                      <TextField
                        type="date"
                        name="endDate"
                        label="End Date"
                        InputLabelProps={{ shrink: true }}
                        value={values.endDate}
                        onChange={handleChange}
                      />
                    </Stack>

                    <Button variant="contained" type="submit" sx={{ marginTop: '10px', bgcolor: "#136586", ":hover": { background: "#105470" } }}>
                      Filter
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Menu>
        </Box>
      </div>
    </>
  );
};

export default Filter;
