import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';

// file pond import

import { FilePond, File, registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { Box, Container, Divider, Stack } from '@mui/material';
import axios from 'axios';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import { useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const emails = ['username@gmail.com', 'user02@gmail.com'];

function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;
  const [files, setFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchParams] = useSearchParams();
  const taskId = searchParams.get('taskId');
  const projectId = searchParams.get('projectId');

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  const handleFileUpload = async () => {
    // console.log('project', taskId, projectId);
    setIsSubmitting(true);
    try {
      const data = new FormData();
      data.append('taskId', taskId);
      data.append('projectId', projectId);
      console.log('project', taskId, projectId);
      files.forEach((item) => {
        data.append('image', item.file);
      });
      const res = await axios
        .post(`${process.env.REACT_APP_API_URL}/task/user/attachfile`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          if (res.data.isError) {
            console.log('res', res);
            toast.error('Something Went Wrong');
            setIsSubmitting(false);
          } else {
            handleClose();
            setIsSubmitting(false);
            toast.success('File Uploaded');
            window.location.reload();
          }
        })
        .catch((err) => {
          setIsSubmitting(false);
          toast.error('Something Went Wrong');
          console.log(err);
        });
    } catch (error) {
      setIsSubmitting(false);
      toast.error('Something Went Wrong');
      console.log(error);
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <DialogTitle>File Upload</DialogTitle>
      <Divider />
      <Container style={{ padding: '20px' }}>
        <Stack padding={2} marginTop={2} spacing={2} sx={{ boxShadow: '0 0 5px 0px #000', borderRadius: '5px' }}>
          <Box>
            <Typography variant="h6" paddingBottom={1}>
              Attach Images
            </Typography>
            <FilePond
              files={files}
              onupdatefiles={setFiles}
              allowMultiple
              maxFiles={50}
              credits={false}
              // server="/api"
              name="files"
              labelIdle="Drag & Drop your files"
            />
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <LoadingButton disabled={!files.length} loading={isSubmitting} variant="contained" onClick={handleFileUpload}>
                Upload Files
              </LoadingButton>
            </Box>
          </Box>
        </Stack>
      </Container>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function FileUploadModal() {
  const [open, setOpen] = React.useState(false);

  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>

      <Button sx={{ bgcolor: "#475985", ":hover": { background: "#394970" } }} startIcon={<AddCircleOutlineSharpIcon />} variant="contained" onClick={handleClickOpen}>
        Files
      </Button>
      <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} />
    </div>
  );
}
