import { useState, useEffect } from 'react';
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import Moment from 'moment';
import axios from 'axios';

// material
import {
  Container,
  Stack,
  Typography,
  CircularProgress,
  Card,
  Button,
  Grid,
  Box,
  Badge,
  Pagination,
  CardContent,
  AvatarGroup,
  Avatar,
  Divider,
  Tabs,
  Tab,
  TablePagination,
  Tooltip,
  Zoom,
  Chip,
  TextField,
} from '@mui/material';
import { Icon } from '@iconify/react';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import ClearIcon from '@mui/icons-material/Clear';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PeopleSharpIcon from '@mui/icons-material/PeopleSharp';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import FlagCircleRoundedIcon from '@mui/icons-material/FlagCircleRounded';
import CategoryIcon from '@mui/icons-material/Category';
import MessageIcon from '@mui/icons-material/Message';
import LocationOnIcon from '@mui/icons-material/LocationOn';

// components
import Page from '../components/Page';
import Filter from '../components/Filter';
import { ProductSort, ProductList, ProductCartWidget, ProductFilterSidebar } from '../sections/@dashboard/products';
// mock
import PRODUCTS from '../_mock/products';
// import { sizeWidth } from '@mui/system';

// ----------------------------------------------------------------------

export default function Project() {
  const size = 16;
  const [searchParams, setSearchParams] = useSearchParams();
  const category = searchParams.get('category');
  const userId = searchParams.get('userId');
  const [projects, setProjects] = useState([]);
  const [filters, setFilters] = useState([]);
  const [isloading, setIsLoading] = useState(true);
  const [pages, setPages] = useState(0);
  const [projectCount, setProjectCount] = useState(0);
  const [options, setOptions] = useState({ size, page: 0, category, userId });
  const [input, setInput] = useState(1);

  const navigate = useNavigate();

  const handleNextPage = () => {
    navigate('/dashboard/newProject');
  };

  const getProject = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/project/user/all`, {
        params: options,
      })
      .then((res) => {
        if (!res.data.isError) {
          setProjects(res.data.data.projects || []);
          setProjectCount(res.data.data.maxRecords || 0);
          setIsLoading(false);
        }
        console.log(projectCount);
        console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const clearFilters = () => {
    setFilters([]);
    setOptions({ size, page: 0 });
    getProject({ size, page: 0 });
  };

  const handlePageChange = (event, newPage) => {
    setPages(newPage);
    const temp = { ...options, page: newPage };
    setOptions(temp);
    setIsLoading(true);
    getProject(temp);
  };
  const handleDelete = (data) => {
    const selected = data[0];
    setFilters(filters.filter((e) => e !== data));
    setOptions((current) => {
      const copy = { ...current };
      delete copy[selected];
      return copy;
    });
    getProject(options);
  };
  const applyFilters = (filter) => {
    const temp = { page: 0, size };

    if (filter.name.trim()) {
      temp.name = filter.name.trim();
    }
    if (filter.location.trim()) {
      temp.location = filter.location.trim();
    }
    if (filter.category.trim()) {
      temp.category = filter.category.trim();
    }

    if (filter.startDate.length) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate.length) {
      temp.endDate = filter.endDate;
    }
    if (filter.userId.length) {
      temp.userId = filter.userId;
    }

    setOptions(temp);
    let filterEntries = Object.entries(filter);
    filterEntries = filterEntries.filter((item) => {
      return item[1] !== '';
    });
    setFilters(filterEntries);
    console.log(filterEntries);
    setPages(0);
    getProject(temp);
    console.log(filters);
  };

  const goToPage = () => {
    console.log('hi');
    if (input > Math.ceil(projectCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getProject(temp);
  };

  useEffect(() => {
    console.log(options);
    getProject(options);
  }, [options]);

  useEffect(() => {
    if (category && !userId) {
      setFilters([['category', category]]);
    }
    if (userId && !category) {
      setFilters([['userId', userId]]);
    }
    if (category && userId) {
      setFilters([
        ['category', category],
        ['userId', userId],
      ]);
    }
  }, [category, userId]);

  const projectColors = ['#f0f2f7', '#f0f2f7', '#f0f2f7', '#f0f2f7'];
  return (
    <Page title="Dashboard: Projects">
      {isloading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
            <Typography marginBottom={1} variant="h4">
              Projects
            </Typography>
            <Filter options={options} applyFilters={applyFilters} />
          </Stack>

          <Card>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} margin={1} justifyContent="space-between">
              <Stack direction="row" spacing={2} padding={0} mb={2}>
                <TextField
                  size="small"
                  style={{ maxWidth: '100px', maxHeight: '35px' }}
                  type="number"
                  component="div"
                  label="Go to"
                  value={input}
                  onInput={(e) => setInput(e.target.value)}
                />
                <Button
                  variant="contained"
                  onClick={goToPage}
                  sx={{ maxHeight: '35px', bgcolor: '#136586', ':hover': { background: '#105470' }, color: 'white' }}
                >
                  Go
                </Button>
              </Stack>
              <Grid direction="row" spacing={1} flex="wrap">
                {filters.map((item, key) => {
                  return <Chip key={key} label={`${item[0]} : ${item[1]}`} onDelete={() => handleDelete(item)} />;
                })}
                {!!filters.length && (
                  <Button size="small" onClick={clearFilters} startIcon={<ClearIcon />}>
                    Clear Filters
                  </Button>
                )}
              </Grid>{' '}
            </Stack>
            <Box paddingTop={'10px'}>
              <Grid
                sx={{
                  padding: '10px',
                  '@media screen and (max-width: 450px)': {
                    padding: '4px',
                  },
                }}
                container
                spacing={1}
                justifyContent="flex-start"
                // direction='row'
                // </Box>justify='flex-start'
                // alignItem='flex-start'
              >
                {projects?.map((item, id) => (
                  <Grid key={id} item sm={6} xs={12} md={4}>
                    <Link
                      style={{
                        textDecoration: 'none',
                      }}
                      className="link"
                      to={`/dashboard/project/${item._id}`}
                    >
                      <Card
                        key={id}
                        // style={{ minHeight: '500px', height: '500px', backgroundColor: projectColors[id % 4] }}
                        style={{ backgroundColor: projectColors[id % 4] }}
                      >
                        <Box
                          sx={{
                            paddingX: '15px',
                            paddingY: '15px',
                            '@media screen and (max-width: 450px)': {
                              paddingX: '10px',
                            },
                          }}
                        >
                          <Grid xs={12} container direction="row" rowSpacing={3}>
                            <Grid item xs={12}>
                              <Stack direction={'row'} justifyContent="space-between">
                                <Typography
                                  variant="h6"
                                  whiteSpace="nowrap"
                                  overflow="hidden"
                                  textOverflow="ellipsis"
                                  color="#02071f"
                                >
                                  {item.name.toUpperCase()}
                                </Typography>
                              </Stack>
                            </Grid>

                            <Grid item xs={12}>
                              <Stack direction={'row'} justifyContent="space-between" alignItems={'center'}>
                                <AvatarGroup max={5} sx={{ display: 'flex', justifyContent: 'left' }}>
                                  {item.members.map((mdata, id) => (
                                    <Tooltip key={id} TransitionComponent={Zoom} title={mdata.email}>
                                      <Badge
                                        invisible={!mdata.isBlocked}
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                        badgeContent={
                                          <Icon fontSize="15px" icon="material-symbols:block" color="red" />
                                        }
                                      >
                                        {!mdata.profilePic ? (
                                          <Avatar alt="" key={id} sx={{ bgcolor: '#3b93b8' }}>
                                            {mdata.fullname.charAt(0).toUpperCase()}
                                          </Avatar>
                                        ) : (
                                          <Avatar alt="" key={id} src={mdata.profilePic} sx={{ bgcolor: '#3b93b8' }} />
                                        )}
                                      </Badge>
                                    </Tooltip>
                                  ))}
                                </AvatarGroup>
                                <Tooltip TransitionComponent={Zoom} title={item.manager.email}>
                                  {!item.manager.profilePic ? (
                                    <Avatar alt="" key={id} sx={{ bgcolor: '#0f5673' }}>
                                      {item.manager.fullname.charAt(0).toUpperCase()}
                                    </Avatar>
                                  ) : (
                                    <Avatar alt="" key={id} src={item.manager.profilePic} sx={{ bgcolor: '#0f5673' }} />
                                  )}
                                </Tooltip>
                              </Stack>
                            </Grid>
                            {/* <Box>
                            <Box padding={1}>
                              <Grid item xs={12}>
                                <Stack direction={'row'} justifyContent="space-between">
                                  <Typography
                                    variant="h6"
                                    whiteSpace="nowrap"
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                  >
                                    {item.name.toUpperCase()}
                                  </Typography>
                                  <ModeEditOutlinedIcon />
                                </Stack>
                              </Grid>
                              <Grid item xs={12}>
                                <Stack direction={'row'} justifyContent="space-between" alignItems={'center'}>
                                  <AvatarGroup max={5} sx={{ display: 'flex', paddingY: '15px' }}>
                                    {item.members.map((mdata, id) => {
                                      return (
                                        <Tooltip TransitionComponent={Zoom} title={mdata.email}>
                                          <Badge
                                            invisible={!mdata.isBlocked}
                                            overlap="circular"
                                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                            badgeContent={
                                              <Icon fontSize="20px" icon="material-symbols:block" color="red" />
                                            }
                                          >
                                            <Avatar alt="" key={id}>
                                              {mdata.fullname.charAt(0).toUpperCase()}
                                            </Avatar>
                                          </Badge>
                                        </Tooltip>
                                      );
                                    })}
                                  </AvatarGroup>
                                  <Tooltip TransitionComponent={Zoom} title={item.manager.email}>
                                    <Avatar alt="" key={id} sx={{ bgcolor: '#a8b1ba' }}>
                                      {item.manager?.fullname.charAt(0).toUpperCase()}
                                    </Avatar>
                                  </Tooltip>
                                </Stack>
                              </Grid>
                            </Box> */}

                            {/* <Grid container sx={12} direction={'row'} spacing={2}>
                              <Grid item sm={6} xs={12} md={6}>
                                <Stack>
                                  <Stack direction={'row'}>
                                    <PeopleSharpIcon style={{ fontSize: 20, paddingTop: '4px' }} />
                                    <Typography style={{ fontSize: '16px', fontWeight: 'bold', paddingLeft: '3px' }}>
                                      Manager
                                    </Typography>
                                  </Stack>
                                  <Typography style={{ fontSize: '13px', paddingLeft: '10px' }}>
                                    {item.manager.fullname.toUpperCase()}
                                  </Typography>
                                </Stack>
                              </Grid>
                              <Grid item sm={6} xs={12} md={6}>
                                <Stack direction={'row'}>
                                  <AttachFileIcon style={{ fontSize: 20, paddingTop: '4px' }} />
                                  <Typography style={{ fontSize: '16px', fontWeight: 'bold', paddingLeft: '3px' }}>
                                    {item.attachmentsCount} Files
                                  </Typography>
                                </Stack>
                              </Grid>

                              <Grid item sm={6} xs={12} md={6}>
                                <Stack>
                                  <Stack direction={'row'}>
                                    <CategoryIcon style={{ fontSize: 20, paddingTop: '4px' }} />
                                    <Typography style={{ fontSize: '16px', fontWeight: 'bold', paddingLeft: '3px' }}>
                                      Category
                                    </Typography>
                                  </Stack>
                                  <Typography style={{ wordWrap: 'break-word', fontSize: '13px', paddingLeft: '10px' }}>
                                    {item.category.toUpperCase()}
                                  </Typography>
                                </Stack>
                              </Grid>

                              <Grid item sm={6} xs={12} md={6}>
                                <Stack>
                                  <Stack direction={'row'}>
                                    <LocationOnIcon style={{ fontSize: 20, paddingTop: '4px' }} />
                                    <Typography style={{ fontSize: '16px', fontWeight: 'bold', paddingLeft: '3px' }}>
                                      Location
                                    </Typography>
                                  </Stack>
                                  <Typography
                                    whiteSpace="nowrap"
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    style={{ fontSize: '13px', paddingLeft: '10px' }}
                                  >
                                    {item.location.toUpperCase()}
                                  </Typography>
                                </Stack>
                              </Grid>

                              <Grid item sm={6} xs={12} md={6}>
                                <Stack>
                                  <Stack direction={'row'}>
                                    <FlagCircleRoundedIcon style={{ fontSize: 20, paddingTop: '4px' }} />
                                    <Typography style={{ fontSize: '16px', fontWeight: 'bold', paddingLeft: '3px' }}>
                                      Start Date
                                    </Typography>
                                  </Stack>
                                  <Typography style={{ fontSize: '13px', paddingLeft: '10px' }}>
                                    {Moment(item.startDate).format('DD/MMM/YYYY')}
                                  </Typography>
                                </Stack>
                              </Grid>

                              <Grid item sm={6} xs={12} md={6}>
                                <Stack>
                                  <Stack direction={'row'}>
                                    <AssignmentTurnedInIcon style={{ fontSize: 20, paddingTop: '4px' }} />
                                    <Typography style={{ fontSize: '16px', fontWeight: 'bold', paddingLeft: '3px' }}>
                                      End Date
                                    </Typography>
                                  </Stack>
                                  <Typography style={{ fontSize: '13px', paddingLeft: '10px' }}>
                                    {Moment(item.endDate).format('DD/MMM/YYYY')}
                                  </Typography>
                                </Stack>
                              </Grid>

                            </Grid> */}

                            {/* <Divider sx={{ paddingTop: '20px' }} />
                            <Typography
                              textAlign="center"
                              paddingTop="20px"
                              style={{ fontSize: '16px', fontWeight: 'bold' }}
                            >
                              View More
                            </Typography> */}
                            {/* </Box> */}
                          </Grid>
                        </Box>
                      </Card>
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={projectCount}
              page={pages}
              onPageChange={handlePageChange}
              rowsPerPage={size}
              // onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton
              showLastButton
            />
          </Card>
        </Container>
      )}
    </Page>
  );
}
