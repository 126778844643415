import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';

const Graph = ({ data }) => {
  useEffect(() => {
    if (data) {
      setOptions({
        xaxis: {
          categories: data?.map((item, index) => item?.category),
        },

        colors: ['	#546E7A', '#008FFB'],
        // colors:['#b7cdfb','#ccdcfc']
      });
    }
    setseries([
      {
        name: 'Projects',
        data: data?.map((item, index) => item?.totalProjects),
      },
    ]);
  }, [data]);

  const [options, setOptions] = useState({
    xaxis: {
      categories: data?.map((item, index) => item?.category),
    },

    colors: ['	#546E7A', '#008FFB'],
  });
  const [series, setseries] = useState([
    {
      name: 'Projects',
      data: data?.map((item, index) => item?.totalProjects),
    },
  ]);

  return (
    <div>
      {data.length > 0 && series && options && (
        <Chart options={options} series={series} type="bar" style={{ width: '100%' }} height={420} />
      )}
    </div>
  );
};

export default Graph;
