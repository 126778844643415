import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
  FormLabel,
  Grid,
  Select,
  InputLabel,
  MenuItem,
  TextareaAutosize,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import axios from 'axios';
import Iconify from './Iconify';

export const EditUserForm = ({ item, closePopup, getUsers }) => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    fullname: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!'),
    accType: Yup.string(),
    mobile: Yup.string(),
    address: Yup.string(),
    password: Yup.string(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullname: item.fullname,
      accType: item.accType,
      address: item.address,
      id: item._id,
      mobile: item.mobile,
      password: item.password,
    },
    validationSchema: RegisterSchema,
    onSubmit: async () => {
      try {
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/member/partner/update`, values, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        console.log('res', response);
        if (response.data.message === 'Member successfully updated') {
          // navigate('/dashboard/member', {replace:true})
          closePopup();
          getUsers();
        } else {
          console.log(response.data);
        }
      } catch (error) {
        console.log(error);
        return false;
      }

      // if (response.data.message === 'Member successfully created') {
      //   navigate('/dashboard/member', { replace: true });
      // } else {
      //   console.log(response.data);
      // }
      //   // if (response) {
      //   //   if (response.data.isError) {
      //   //     if (response.data.message === "session exists") {
      //   //       navigate("/error");
      //   //     } else {
      //   //       setError(response.data.message);
      //   //     }
      //   //   } else {
      //   //     console.log(response);
      //   //     setData(response.data);
      //   //     setVerify(true);
      //   //   }
      //   // }

      //   navigate('/dashboard', { replace: true });
    },
  });

  const { errors, touched, handleSubmit, values, isSubmitting, getFieldProps } = formik;

  // const itemData = formik.setValues(item);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Full name"
              // value={itemData.fullname}
              {...getFieldProps('fullname')}
              error={Boolean(touched.fullname && errors.fullname)}
              helperText={touched.fullname && errors.fullname}
            />

            {/* <TextField
            fullWidth
            label="Last name"
            {...getFieldProps('lastName')}
            error={Boolean(touched.lastName && errors.lastName)}
            helperText={touched.lastName && errors.lastName}
          /> */}
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Role</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="accType"
                {...getFieldProps('accType')}
                label="Role"
                onChange={formik.handleChange}
              >
                <MenuItem value="ARCHITECT">ARCHITECT</MenuItem>
                <MenuItem value="CLIENT">CLIENT</MenuItem>
                <MenuItem value="CONTRACTOR">CONTRACTOR</MenuItem>
                <MenuItem value="EMPLOYEE">EMPLOYEE</MenuItem>
              </Select>
            </FormControl>
          </Stack>

          {/* <TextField
          fullWidth
          autoComplete="username"
          type="email"
          label="Email address"
          {...getFieldProps('email')}
          error={Boolean(touched.email && errors.email)}
          helperText={touched.email && errors.email}
        /> */}
          <TextField
            fullWidth
            label="mobile"
            {...getFieldProps('mobile')}
            error={Boolean(touched.mobile && errors.mobile)}
            helperText={touched.mobile && errors.mobile}
          />
          <TextField
            fullWidth
            label="Address"
            {...getFieldProps('address')}
            error={Boolean(touched.address && errors.address)}
            helperText={touched.address && errors.address}
          />
          {/* <TextareaAutosize
          aria-label="minimum height"
          minRows={3}
          placeholder="Minimum 3 rows"
          style={{ width: 200 }}
        /> */}

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <TextField
            fullWidth
            type="file"
            InputLabelProps={{
              shrink: true,
            }}
            label="Upload Profile Picture"
            onChange={(event) => {
              formik.setFieldValue('profilePic', event.currentTarget.files[0]);
            }}
          />

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Edit User
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
};
