import { AssignmentInd, CalendarMonthOutlined, Close, FlagCircleRounded } from '@mui/icons-material';
import {
  Card,
  Grid,
  Stack,
  Typography,
  CardContent,
  TablePagination,
  CircularProgress,
  useMediaQuery,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Slide,
  Box,
  DialogTitle,
  DialogContent,
  Switch,
  FormControlLabel,
  TextField,
  styled,
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useParams, Link } from 'react-router-dom';
import { CardLogo } from '../components/Logo';
import Page from '../components/Page';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    height: '30px',
    width: '130px',
    padding: 0,
    fontSize: '0.875rem',
  },
  '& .MuiInputLabel-root': {
    fontSize: '0.875rem',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '1.25rem',
  },
  '& .MuiSelect-select': {
    padding: '0 0.5rem',
  },
  '& .MuiSelect-icon': {
    top: 'calc(50% - 1.25rem)',
    padding: 0,
  },
}));

export const TaskDatePopup = ({ setOpen4, manager }) => {
  const [task, setTask] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(true);
  const [editMode, setEditMode] = useState(false);

  const matches = useMediaQuery('(max-width:400px)');

  const { id } = useParams();
  console.log('pro', id);

  const getTask = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/task/user/categories/tasks/all`, {
        params: {
          projectId: id,
        },
      })
      .then((res) => {
        console.log('task', res);
        setTask(res.data.data.result);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleUpdateStartDate = async (e, sub) => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/task/manager/edit`, {
        taskId: sub._id,
        startDate: e.target.value,
        projectId: id,
      });
      toast.success('End date updated');
      getTask();
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong');
    }
  };

  const handleUpdateEndDate = async (e, sub) => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/task/manager/edit`, {
        taskId: sub._id,
        endDate: e.target.value,
        projectId: id,
      });
      toast.success('End date updated');
      getTask();
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong');
    }
  };

  useEffect(() => {
    getTask();
  }, []);

  return (
    <Page>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Dialog open={open} fullScreen={matches} onClose={() => setOpen4(false)} scroll="paper">
          <DialogTitle
            id="scroll-dialog-title"
            sx={{
              padding: 0.5,
            }}
          >
            <Stack
              direction="row"
              justifyContent="flex-end"
              spacing={2}
              alignItems="flex-start"
              sx={{
                width: '100%',
              }}
            >
             {manager &&  <FormControlLabel
                value="start"
                control={<Switch color="primary" checked={editMode} onChange={() => setEditMode(!editMode)} />}
                label="Edit"
                labelPlacement="start"
              />}
              <IconButton onClick={() => setOpen4(false)}>
                <Close />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent
            dividers
            sx={{
              padding: 0,
            }}
          >
            <Box paddingX={1}>
              <Grid container direction="column" spacing={1} paddingX={{ xs: 0.4, sm: 1, md: 2 }}>
                {task.map((item, index) => (
                  <Grid
                    container
                    key={index}
                    spacing={{
                      xs: 0.4,
                      sm: 1,
                    }}
                    padding={2}
                  >
                    <Grid item xs={12} md={12} lg={12}>
                      <Stack direction="row" spacing={1} paddingBottom="15px" alignItems={'center'}>
                        <CardLogo />
                        <Typography
                          wordBreak="break-word"
                          style={{ fontSize: '19px', fontWeight: 'bold', paddingLeft: '3px', paddingTop: '10px' }}
                        >
                          {item.category?.toUpperCase()}
                        </Typography>
                      </Stack>
                    </Grid>
                    {item.task.map((sub, key) => (
                      <Grid key={key} item xs={12} md={12} lg={12} padding={{ xs: 1, sm: 1, md: 2 }}>
                        <Box paddingX={{ xs: 0, sm: 1, lg: 3 }}>
                          <Typography gutterBottom style={{ fontSize: '15px', fontWeight: 'bold', paddingLeft: '3px' }}>
                            {`${key + 1}. ${sub.name} `}
                          </Typography>
                          <Stack direction="row" spacing={1} alignItems={'start'}>
                            <Stack direction="row" flex={1} alignItems={'start'} justifyContent="start">
                              <Stack direction="row" alignItems={'start'}>
                                <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                  <CalendarMonthOutlined sx={{ color: 'green' }} />
                                </Typography>
                              </Stack>
                              {sub.startDate && !editMode && (
                                <Typography style={{ fontSize: '15px' }}>
                                  {moment(sub.startDate).format('DD MMM YYYY')}
                                </Typography>
                              )}
                               {editMode && manager &&(
                                <CustomTextField
                                  type="date"
                                  size="small"
                                  disabled ={sub.startDate}
                                  defaultValue={sub.startDate?moment(sub.startDate).format('YYYY-MM-DD'):''}
                                  onBlur={(e) => handleUpdateStartDate(e, sub)}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              )}
                            </Stack>

                            <Stack direction="row" flex={1} alignItems={'start'}>
                              <Stack direction="row" alignItems={'start'}>
                                <Typography style={{ fontSize: '15px' }}>
                                  <CalendarMonthOutlined sx={{ color: 'red' }} />
                                </Typography>
                              </Stack>
                              {sub.endDate && !editMode && (
                                <Typography style={{ fontSize: '15px' }}>
                                  {moment(sub.endDate).format('DD MMM YYYY')}
                                </Typography>
                              )}
                                {editMode && manager && (
                                <CustomTextField
                                  type="date"
                                  size="small"
                                  disabled ={sub.endDate}
                                  defaultValue={sub.endDate?moment(sub.endDate).format('YYYY-MM-DD'):''}
                                  onBlur={(e) => handleUpdateEndDate(e, sub)}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              )}
                            </Stack>
                          </Stack>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                ))}
              </Grid>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </Page>
  );
};
