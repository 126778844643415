import { useRef, useState } from 'react';
import { Link as RouterLink, Navigate, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton } from '@mui/material';
import { PowerSettingsNew } from '@mui/icons-material';
// components
import axios from 'axios';
import MenuPopover from '../../components/MenuPopover';
// mocks_
import account from '../../_mock/account';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
    linkTo: '/',
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
    linkTo: '/dashboard/profile',
  },

];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const profile = JSON.parse(localStorage.getItem('profile'));
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const token = localStorage.getItem('accessToken');
  const accountData = JSON.parse(localStorage.getItem('profile'));
  // console.log(token);

  const handleClose = async () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    setOpen(null);

    const response = await axios
      .post(`${process.env.REACT_APP_API_URL}/account/user/signout`, {})
      .then((response) => {
        localStorage.clear();
        navigate('/', { replace: true });
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
       <Avatar src={profile?.profilePic || account.photoURL} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {accountData?.fullname && accountData?.fullname.toUpperCase()}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {accountData?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              <Iconify icon={option.icon} sx={{ width: 20, height: 20, mr: 1 }} />
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          <Stack direction="row" alignItems="center" spacing={1} justifyContent="center" sx={{ width: "100%" }}>
            <Typography variant="body2" sx={{ color:"primary.main" }}>Logout</Typography>
            <PowerSettingsNew sx={{ fontSize: "20px", color: "primary.main" }} />
          </Stack>
        </MenuItem>
      </MenuPopover>
    </>
  );
}
