import React, { useEffect, useState } from 'react';
import {
  Card,
  Container,
  Grid,
  Stack,
  Box,
  Typography,
  CardContent,
  TablePagination,
  CircularProgress,
  AvatarGroup,
  Tooltip,
  Avatar,
  Zoom,
  Divider,
  useMediaQuery,
  TextField,
  Button,
} from '@mui/material';
import axios from 'axios';
import Moment from 'moment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import FlagCircleRoundedIcon from '@mui/icons-material/FlagCircleRounded';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { useParams, Link } from 'react-router-dom';
import Logo, { CardLogo } from './Logo';
import Page from './Page';

export default function ListTasks() {
  const [task, setTask] = useState([]);
  const [taskCount, setTaskCount] = useState(0);
  const [pages, setPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const size = 9;
  const TaskColors = ['#ede8e8'];
  const { id } = useParams();
  console.log('pro', id);
  const matches = useMediaQuery('(max-width:600px)');
  const [input, setInput] = useState(1);

  // const getTask = (newPage) => {
  //   axios
  //     .get(`${process.env.REACT_APP_API_URL}/task/user/categories`, {
  //       params: { projectId: id, page: newPage, size },
  //     })
  //     .then((res) => {
  //       console.log('task', res);
  //       setTask(res.data.data.records);
  //       setIsLoading(false);
  //       setTaskCount(res.data.data.maxRecord);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setIsLoading(false);
  //     });
  // };
  const getTask = (newPage) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/task/user/categories/tasks`, {
        params: { projectId: id },
      })
      .then((res) => {
        console.log('task', res);
        setTask(res.data.data.records);
        setIsLoading(false);
        setTaskCount(res.data.data.maxRecord);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  const handlePageChange = (event, newPage) => {
    setPages(newPage);
    setIsLoading(true);
    getTask(newPage);
  };

  const goToPage = () => {
    console.log('hi');
    if (input > Math.ceil(taskCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    getTask(input - 1 >= 0 ? input - 1 : 0);
  };

  useEffect(() => {
    getTask();
  }, []);

  return (
    <Page>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Stack direction="row" spacing={2}>
            <TextField
              size="small"
              style={{ maxWidth: '100px', maxHeight: '35px' }}
              type="number"
              component="div"
              label="Go to"
              value={input}
              onInput={(e) => setInput(e.target.value)}
            />
            <Button
              variant="contained"
              onClick={goToPage}
              sx={{ maxHeight: '35px', bgcolor: '#575151', ':hover': { background: '#423e3e' } }}
            >
              Go
            </Button>
          </Stack>
          <Grid container spacing={1} sx={12} mt={1}>
            {task?.map((item, index) => {
              return (
                <Grid item sm={6} xs={12} md={4}>
                  <Link
                    to={`/dashboard/project/task?category=${item.category}&id=${id}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <Card
                      key={index}
                      style={{
                        background: TaskColors[index % 1],
                        paddingBottom: '10px',
                        height: '100%',
                        ...(!matches && { minHeight: '400px' }),
                      }}
                    >
                      <CardContent
                        style={{
                          maxHeight: '400px',
                          overflowY: 'auto',
                        }}
                        className="scrollCard"
                      >
                        <Stack direction="row" spacing={1} paddingBottom="15px" alignItems="center">
                          <CardLogo />
                          <Typography
                            whiteSpace="nowrap"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            style={{ fontSize: '19px', fontWeight: 'bold', paddingLeft: '3px', paddingTop: '10px' }}
                          >
                            {item.category?.toUpperCase()}
                          </Typography>
                        </Stack>
                        <Grid container sx={12} direction="row" justifyContent="space-between">
                          {item.task.map((name, id) => (
                            <Grid item key={id} sm={10} xs={10} md={10} style={{ paddingTop: '15px' }}>
                              <Typography style={{ fontSize: '15px', fontWeight: 'bold', paddingLeft: '3px' }}>
                                {name.name}
                              </Typography>
                            </Grid>
                          ))}
                        </Grid>
                        {/* <Grid container sx={12} direction="row" justifyContent="space-between">
                          <Grid item sm={10} xs={10} md={10} style={{ paddingTop: '15px' }}>
                            <Stack direction={'row'}>
                              <FlagCircleRoundedIcon />
                              <Typography style={{ fontSize: '18px', fontWeight: 'bold', paddingLeft: '3px' }}>
                                Task Count
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid item direction="row" sm={2} xs={2} md={2} style={{ paddingTop: '15px' }}>
                            <Typography style={{ fontSize: '15px', fontWeight: 'bold', paddingLeft: '10px' }}>
                              {item.taskCount}
                            </Typography>
                          </Grid>
                          <Grid item sm={10} xs={10} md={10} style={{ paddingTop: '15px' }}>
                            <Stack direction={'row'}>
                              <FlagCircleRoundedIcon />
                              <Typography style={{ fontSize: '18px', fontWeight: 'bold', paddingLeft: '3px' }}>
                                Attachments Count
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid item direction="row" sm={2} xs={2} md={2} style={{ paddingTop: '15px' }}>
                            <Typography style={{ fontSize: '15px', fontWeight: 'bold', paddingLeft: '10px' }}>
                              {item.attachmentsCount}
                            </Typography>
                          </Grid>
                          <Grid item sm={10} xs={10} md={10} style={{ paddingTop: '15px' }}>
                            <Stack direction={'row'}>
                              <FlagCircleRoundedIcon />
                              <Typography style={{ fontSize: '18px', fontWeight: 'bold', paddingLeft: '3px' }}>
                                Completed Task Count
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid item direction="row" sm={2} xs={2} md={2} style={{ paddingTop: '15px' }}>
                            <Typography style={{ fontSize: '15px', fontWeight: 'bold', paddingLeft: '10px' }}>
                              {item.completedTaskCount}
                            </Typography>
                          </Grid>

                          <Grid item sm={10} xs={10} md={10} style={{ paddingTop: '15px' }}>
                            <Stack direction={'row'}>
                              <FlagCircleRoundedIcon />
                              <Typography style={{ fontSize: '18px', fontWeight: 'bold', paddingLeft: '3px' }}>
                                In Progress Task Count
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid item direction="row" sm={2} xs={2} md={2} style={{ paddingTop: '15px' }}>
                            <Typography style={{ fontSize: '15px', fontWeight: 'bold', paddingLeft: '10px' }}>
                              {item.inProgressTaskCount}
                            </Typography>
                          </Grid>

                          <Grid item sm={10} xs={10} md={10} style={{ paddingTop: '15px' }}>
                            <Stack direction={'row'}>
                              <FlagCircleRoundedIcon />
                              <Typography style={{ fontSize: '18px', fontWeight: 'bold', paddingLeft: '3px' }}>
                                Paused Task Count
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid item direction="row" sm={2} xs={2} md={2} style={{ paddingTop: '15px' }}>
                            <Typography style={{ fontSize: '15px', fontWeight: 'bold', paddingLeft: '10px' }}>
                              {item.pausedTaskCount}
                            </Typography>
                          </Grid>

                          <Grid item sm={10} xs={10} md={10} style={{ paddingTop: '15px' }}>
                            <Stack direction={'row'}>
                              <FlagCircleRoundedIcon />
                              <Typography style={{ fontSize: '18px', fontWeight: 'bold', paddingLeft: '3px' }}>
                                Pending Count
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid item direction="row" sm={2} xs={2} md={2} style={{ paddingTop: '15px' }}>
                            <Typography style={{ fontSize: '15px', fontWeight: 'bold', paddingLeft: '10px' }}>
                              {item.pendingTaskCount}
                            </Typography>
                          </Grid>

                          <Grid item sm={10} xs={10} md={10} style={{ paddingTop: '15px' }}>
                            <Stack direction={'row'}>
                              <FlagCircleRoundedIcon />
                              <Typography style={{ fontSize: '18px', fontWeight: 'bold', paddingLeft: '3px' }}>
                                Cancelled Task Count
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid item direction="row" sm={2} xs={2} md={2} style={{ paddingTop: '15px' }}>
                            <Typography style={{ fontSize: '15px', fontWeight: 'bold', paddingLeft: '10px' }}>
                              {item.cancelledTaskCount}
                            </Typography>
                          </Grid>
                        </Grid> */}
                      </CardContent>
                    </Card>
                  </Link>
                </Grid>
              );
            })}
          </Grid>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={taskCount}
            page={pages}
            onPageChange={handlePageChange}
            rowsPerPage={size}
            // onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton
            showLastButton
          />
        </>
      )}
    </Page>
  );
}
