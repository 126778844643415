// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'member',
    path: '/dashboard/member',
    icon: getIcon('eva:people-fill'),
    permission: ["PARTNER"],
  },
  {
    title: 'projects',
    path: '/dashboard/projects',
    icon: getIcon('icons8:parallel-tasks'),
  },
];

export default navConfig;
