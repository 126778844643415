import * as React from 'react';
import Moment from 'moment';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import fileImage from '../assets/file.png';
import './Styles.css'

export default function CardForAttachment({ file }) {
  const theme = useTheme();
  const fileType = [
    'image/apng',
    'image/bmp',
    'image/gif',
    'image/x-icon',
    'image/jpeg',
    'image/png',
    '	image/svg+xml	',
    'image/tiff',
    'image/webp',
  ];

  const saveFile = () => {
    window.location.href = `${file.downloadURL}`;
  };
  return (
    <Card sx={{ display: 'flex' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#f0f2f7' }}>
        <CardContent sx={{ flex: '1 0 auto', minWidth: '210px', maxWidth: '210px' }}>
          <Typography
            component="div"
            variant="h6"
            style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'noWrap' }}
          >
            {file.isAdmin ? 'Admin' : file.userId.fullname.charAt(0).toUpperCase() + file.userId.fullname.slice(1)}          </Typography>
          <Typography component="div" variant="p">
            {Moment(file.createdAt).format('DD/MM/YYYY')}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
            style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'noWrap' }}
          >
            {file.originalName}
          </Typography>
        </CardContent>
        <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center', pl: 1, pb: 1 }}>
          <Button
            variant="outlined"
            style={{ color: "#475985" }}
            startIcon={<RemoveRedEyeOutlinedIcon />}
            onClick={() => window.open(file.fileURL, '_blank')}
          >
            View
          </Button>
          <Button variant="outlined"
            style={{ color: "#475985" }}
            onClick={saveFile}>
            Download
          </Button>
        </Box>
      </Box>
      {fileType.includes(file.mimeType) ? (
        <CardMedia component="img" className='imge' sx={{ width: 151, height: 168 }} image={file.fileURL} alt="Live from space album cover" />
      ) : (
        <CardMedia
          component="img"
          className='imge'
          sx={{ width: 151 }}
          src={fileImage}
          alt="Live from space album cover"
            style={{ objectFit: 'contain', padding: '30px', backgroundColor: '#eff1f6', borderLeft: '1px solid white' }}
        />
      )}
    </Card>
  );
}
